/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, tt, var,
u, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* ----------------------------------------------------------------------------------------------------- */

body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.mapboxgl-popup {
  padding-bottom: 30px;
}

.mapboxgl-popup-content {
  font: 400 14px/18px 'Source Sans Pro', 'Helvetica Neue', sans-serif;
  padding: 0 !important;
  width: 250px;
}

.mapboxgl-popup-content div h3 {
  background: #91c949;
  color: #fff;
  margin: 0;
  padding: 10px 8px;
  border-radius: 3px 3px 0 0;
  font-weight: 700;
	text-align: center;
}

.mapboxgl-popup-content div h4 {
  margin: 0;
  padding: 10px 5px;
  font-weight: 700;
	text-align: center;
}

.mapboxgl-popup-content div h5 {
  margin: 0;
  padding: 0;
  font-weight: 400;
	text-align: center;
	font-style: italic;
	font-size: 11px;
	color: rgb(100,100,100);
}

.mapboxgl-popup-anchor-top > .mapboxgl-popup-content {
  margin-top: 15px;
}

.mapboxgl-popup-anchor-top > .mapboxgl-popup-tip {
  border-bottom-color: #91c949;
}

.mapboxgl-popup-close-button {
  display: none;
}

.mapboxgl-popup-tip {
	display: none;
}

.marker {
  border: none;
  cursor: pointer;
  height: 40px;
  width: 20px;
  background-image: url('./Components/LocationComponents/pin-blue.svg');
}

.curr-marker {
  border: none;
  cursor: pointer;
  height: 40px;
  width: 20px;
  background-image: url('./Components/LocationComponents/pin-red.svg');
}

.aFormula, .pFormula {
  font-size: 14px;
  width: fit-content;
  font-style: italic;
  margin: 0;
  margin-left: 20px;
}

.rmdp-container {
  width: fit-content;
}

.rmdp-input {
  padding-right: 24px !important;
  height: 49px !important;
  border-radius: 4px !important;
  border-color: rgba(0,0,0,0.23) !important;
  font-size: 16px;
  font-weight: 400;
  font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Roboto", "Arial", "sans-serif";
  color: rgba(0,0,0,0.87);
}

.rmdp-input:hover {
  border-color: rgba(0,0,0,0.87) !important;
}

.rmdp-input:focus {
  box-shadow: inset 0px 0px 0px 2px #1976d2 !important;
  border-color: rgba(0,0,0,0) !important;
}

@media only screen and (max-width: 745px) {
  .aFormula {
    font-size: 13px;
  }
}

@media only screen and (max-width: 616px) {
  .aFormula {
    width: 243px;
    margin: 0 auto;
  }

  .pFormula {
    width: fit-content;
    margin: 0;
    margin-left: 20px;
  }
}

@media only screen and (max-width: 390px) {
  .pFormula {
    width: 163px;
    margin: 0 auto;
    text-align: center;
  }
}

@media only screen and (max-width: 380px) {
  .aFormula {
    width: 200px;
    margin: 0 auto;
    text-align: center;
  }
}